import * as Sentry from '@sentry/node';
import NextErrorComponent from 'next/error';
import { NextSeo } from 'next-seo';
import { withAnimation } from '../utils/hoc';
import logger from '@utils/logger';
import Error404 from '@components/lotties/Error404';

function Error(props) {
	const { statusCode, hasGetInitialPropsRun, err, admin } = props;

	if (!hasGetInitialPropsRun && err) {
		// getInitialProps is not called in case of
		// https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
		// err via _app.js so it can be captured
		Sentry.captureException(err);

		logger.error('send to Sentry: ', err);
	}

	logger.error(statusCode + ' Page not Found');

	return (
		<>
			<NextSeo title="404 Page Not found" titleTemplate={`%s | ${admin?.name}`} />
			<section className="hero is-link is-fullheight diagonal-hero-bg">
				<div className="hero-body center">
					<div>
						<h1 className="title is-1 is-capitalized has-text-black">
							{statusCode === 404
								? 'Oups!  ⁞ ✪ ⌂ ✪ ⁞  404 Page not Found'
								: 'An error occurred in the website'}
						</h1>
						<Error404 />
					</div>
				</div>
			</section>
		</>
	);
}

Error.getInitialProps = async ({ res, err, asPath, admin }) => {
	const errorInitialProps = await NextErrorComponent.getInitialProps({
		res,
		err
	});

	errorInitialProps.hasGetInitialPropsRun = true;

	if (res?.statusCode === 404) {
		// Opinionated: do not record an exception in Sentry for 404
		return { admin, statusCode: res.statusCode };
	}
	if (err) {
		Sentry.captureException(err);
		await Sentry.flush(2000);
		return errorInitialProps;
	}

	// If this point is reached, getInitialProps was called without any
	// information about what the error might be. This is unexpected and may
	// indicate a bug introduced in Next.js, so record it in Sentry
	Sentry.captureException(new Error(`_error.js getInitialProps missing data at path: ${asPath}`));
	await Sentry.flush(2000);

	return errorInitialProps;
};

export default withAnimation(Error);
