import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/json/error.json';

export default function Error404() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: require('../../assets/json/error.json'),
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};
	return (
		<div style={{ width: '100%' }}>
			<Lottie options={defaultOptions} />
		</div>
	);
}
